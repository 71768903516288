/* CarouselComponent.css */
body{
  font-family: 'Chakra Petch', sans-serif;
}
.carousel-wrapper {
  overflow: hidden; /* Hide any overflow to prevent scrollbar */
}

.carousel .slide img {
  max-width: 100%;
  max-height: calc(100vh - 100px); /* Adjust the maximum image height */
  margin: 0 auto; /* Center the images horizontally */
}
::-webkit-scrollbar {
  width: 0px;
  height: 5px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-spin {
  height: 100vh !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.ant-spin .ant-spin-dot .ant-spin-dot-item {
  background-color: green;
}
.ant-dropdown-menu {
  background-color: #ffffff !important;
  border-radius: 0px !important;
}
.ant-float-btn-body {
  background-color: green !important;
}
.ant-float-btn-icon {
  color: white !important;
}
/* .contact-us-header {
  background-image: url("./Assets//contact.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 100%;
  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px); 
  margin: 0 auto; 
} */
.content-div {
  /* background-image: url("https://images.unsplash.com/photo-1522093007474-d86e9bf7ba6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.content-div:hover {
  background-image: linear-gradient(
      to right,
      #e84022,
      hsla(28, 68%, 70%, 0.801)
    ),
    url("https://images.unsplash.com/photo-1522093007474-d86e9bf7ba6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80");
}

.image-cover {
  height: 260px;
}
/*
  -remove the classes below if you have the 'group-hover'property added in your tailwind config file
  -also remove the class from the html template
  */
.content-div:hover .fd-cl {
  opacity: 0.25;
}
.content-div:hover .fd-sh {
  opacity: 1;
}
.ant-table-thead tr th{
  background-color: #2a887ddb !important;
  color: #ffffff !important;
}
.slider-container .imageSlides{
  display: flex !important;
  justify-content: center ;
  align-items: center ;
  flex-direction: column;
}
.slider-containe .slick-slider .slick-arrow .slick-next{
  border: 1px solid black !important;
  background: black !important;
}
/* Add your custom styles for the previous and next buttons */
.slick-prev,
.slick-next {
  background-color: #2a887ddb !important; /* Set the background color */
  color: #fff !important; /* Set the text color */
  font-size: 24px !important; /* Set the font size */
  border-radius: 50% !important; /* Make the buttons circular */
  width: 40px !important; /* Set the button width */
  height: 40px !important; /* Set the button height */
  line-height: 40px !important; /* Vertically center the text */
  text-align: center !important; /* Center the text horizontally */
  position: absolute !important; /* Position the buttons relative to the slider */
  top: 50% !important; /* Position the buttons vertically in the middle of the slider */
  transform: translateY(-50%) !important; /* Center the buttons vertically */
  z-index: 1 !important; /* Ensure the buttons are above the slider content */
  cursor: pointer !important; /* Add a pointer cursor on hover */
}

/* Style the previous button */
.slick-prev {
  left: 8px !important; /* Position the previous button on the left side of the slider */
}
.slick-next{
  right:8px !important
}

/* Style the next button */
.slick-next {
  right: 10px;
}